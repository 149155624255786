<!-- 信息素养 -->
<template>
    <div class="baseInfo">
        <el-table :data="InformationList" border max-height="calc(100vh - 300px)" stripe style="width: 100%"
            class="my-table">
            <el-table-column prop="date" label="标题">
                <template #default="scope">
                    <div class="flex" style="flex-wrap: wrap">
                        {{ scope.row.title }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="date" label="封面">
                <template #default="scope">
                    <div class="" style="display: flex;align-items: center;justify-content: center;">
                        <el-image :src="scope.row.cover" alt="" style="width: 80px;height: 80px;border-radius: 4px;"
                            fit="cover" />
                    </div>
                    <!-- <div class="flex" style="flex-wrap: wrap"> -->
                    <!-- {{ scope.row.name }} -->

                    <!-- </div> -->
                </template>
            </el-table-column>
            <el-table-column prop="date" label="创建时间">
                <template #default="scope">
                    <div class="flex" style="flex-wrap: wrap">
                        {{ scope.row.createdTimeStr }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
                <template #default="scope">
                    <div class="table-edit-public">
                        <span class="btn edit" @click="downLoadUrl(scope.row.filePath)">下载</span>
                        <span class="btn delete" @click="deleteOne(scope.row)">删除</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="add-tip">
            <span @click="addLinkHandler">添加信息素养</span>
        </div>

        <addDialog @success="success" ref="addDialogComp" />
    </div>
</template>

<script>
import {
    informationFindListByUnifyVdatabaseDetailsId,
    deleteUnifyInformationLiteracyById,
} from "@/js/network/main/index.js"
import $utils from "@/utils/utils.js"
import { reactive, ref , getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { MessageBox } from "@/js/utils/commonJs";
import { ElMessageBox } from "element-plus";
import addDialog from "./addDialog";
export default {
    components: {
        addDialog
    },
    setup() {
        const route = useRoute();
        const InformationList = ref([]);
        let attachmentListCopy = [];
        const addDialogComp = ref(null);
        
        //添加成功按钮
        const success = function (item) {
            init();
        }
        //添加
        const addLinkHandler = function () {
            addDialogComp.value.init();
        }
        //删除一个
        const deleteOne = function (item) {
            ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
                deleteUnifyInformationLiteracyById({ id: item.id }).then(res => {
                    if (!res.success) {
                        MessageBox.error(res.msg);
                        return;
                    }
                    MessageBox.success("操作成功！");
                    init();
                }).catch(error => {
                    //MessageBox.error(error);
                    console.log(error, "catchError");
                });
            }).catch(() => {
            })

        }

        //获取数据
        const init = function () {
            let { id } = route.query
            informationFindListByUnifyVdatabaseDetailsId({ unifyVdatabaseDetailsId: id }).then(res => {
                if (!res.success) {
                    MessageBox.error(res.msg);
                    return;
                }
                if (res && res.data) {
                    InformationList.value = res.data;
                    attachmentListCopy = JSON.parse(JSON.stringify(res.data));
                }
            }).catch(error => {
                //MessageBox.error(error);
                console.log(error, "catchError");
            });
        }
        //重置
        const reset = () => {
            InformationList.value = JSON.parse(JSON.stringify(attachmentListCopy));
        }

        const downLoadUrl = (url) => {
            if(url){
                $utils.downloadUrl(url);
            }
        }

        init();
        return {
            InformationList,
            addDialogComp,
            success,
            reset,
            addLinkHandler,
            deleteOne,
            downLoadUrl
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-row {
    margin-bottom: 8px;
}

.button-container {
    padding: 10px 0;
    background-color: white;
}


.edit-text-button {
    font-size: 14px;
    color: #3556fc;
    user-select: none;
    cursor: pointer;
    text-decoration: underline;
    margin-right: 12px;
}

.delete-text-button {
    font-size: 14px;
    color: red;
    user-select: none;
    cursor: pointer;
    text-decoration: underline;
}

.add-tip {
    font-size: 14px;
    color: #3157b9;
    cursor: pointer;
    user-select: none;
    margin-top: 12px;
}
</style>
