<template>
  <el-dialog v-model="dialogVisible" title="添加附件" width="30%" :before-close="handleClose">
    <div class="main-container">
      <div class="row  flex a-c">
        <div class="row-tip"><i class="iconfont unify-bitian"></i>名称:</div>
        <el-input style="width: 220px" v-model="state.name" show-word-limit maxlength="100" placeholder="名称" />
      </div>

      <div class="row flex a-c">
        <div class="row-tip"><i class="iconfont unify-bitian"></i>文件:</div>
        <!-- :http-request="uploadFile" -->
        <el-upload ref="attachUpload" class="upload-demo" drag action="/serve/main-server/file/uploadMultipartFile"
          :limit="1" :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-success="onSuccess">
          <!-- 
            :auto-upload="false"
        -->
          <el-icon class="el-icon--upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            拖拽到这或者 <em>点击上传</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              文件大小不得超过150M
            </div>
          </template>
        </el-upload>
      </div>

    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmHandler">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref} from 'vue'
import { uploadMultipartFile, findOneLibrarianById, saveAttachment} from "@/js/network/main/index"
import {useRoute} from "vue-router";
import {UploadFilled} from '@element-plus/icons-vue'
import {Plus} from '@element-plus/icons-vue'
import {MessageBox} from "@/js/utils/commonJs";
import {ElMessage} from 'element-plus'

export default {
  components: {
    Plus,
    UploadFilled
  },
  setup(props, {attrs, slots, emit, expose}) {
    const route = useRoute();
    let attachUpload = ref(null)
    const state = ref({
      name: "",
      url:"",
    })
    const dialogVisible = ref(false)

    const handleClose = () => {
      state.value = {
        name: "",
        url:"",
      }
      attachUpload.value.clearFiles();
      dialogVisible.value = false;
    }
    const init = () => {
      dialogVisible.value = true;
    }
    const confirmHandler = () => {
      if(!state.value.name){
        MessageBox.warning("名称必填!")
        return;
      }
      if(!state.value.url){
        MessageBox.warning("请先上传附件!")
        return;
      }
      let {id} = route.query
      saveAttachment({...state.value, unifyVdatabaseDetailsId: id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        MessageBox.success("保存成功！");
        emit("success",state.value);
        handleClose();
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });


    }
    
    const progressFn = (e) => {
      console.log('e: ', e);
    }

    const onSuccess = (res) => {
      if (!res.success) {
        attachUpload.value.clearFiles();
        MessageBox.error(res.msg);
        return;
      }
      state.value.url = res.data.url;
      MessageBox.success('上传成功!');
    }

    const uploadFile = function (file) {
      var form = new FormData();
      form.append("file", file);
      uploadMultipartFile(form)
          .then((res) => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }

            state.value.url = res.data.url;
            attachUpload.value.handleStart(file);
            MessageBox.success('上传成功!');
          })
          .catch((error) => {
          });
    }
    const beforeAvatarUpload = function (rawFile) {
      const isLtM = rawFile.size / 1024 / 1024 < 150;
      if (!isLtM) {
        ElMessage.error('文件大小超过150MB！');
        return false;
      }
      return true;
    }
    const handleExceed = (files) => {
      // console.log('files: ', files);
      return ElMessage.error('最多一个文件，请删除后再上传！');
      // attachUpload.value.clearFiles();
      // const file = files[0];
      // attachUpload.value.handleStart(file);
      // attachUpload.value.submit()
      // uploadFile(file)
    };
    return {
      state,
      dialogVisible,
      handleClose,
      init,
      confirmHandler,
      beforeAvatarUpload,
      handleExceed,
      uploadFile,
      attachUpload,
      progressFn,
      onSuccess,
      ...props
    }

  }
}


</script>
<style scoped lang="scss">
.upload-demo {
  width: 178px;
  height: 178px;
  display: block;
}

.dialog-footer{
  padding-top: 24px;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

//:deep(.el-input__wrapper) {
//  box-shadow: none !important;
//  background: transparent !important;
//}
.main-container {
  height: 280px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 12px;

  .row-tip {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    margin-right: 12px;
    position: relative;

    .unify-bitian {
      color: red;
      position: absolute;
      left: -15px;
      top: 3px;
    }
  }
}
</style>
