<template>
    <custom-dialog v-model="visible" title="批量管理" @close="close">
        <div class="transfer" v-loading="dataConfig.loading">
            <el-transfer v-model="idList" filterable :filter-method="filterMethod" filter-placeholder="输入筛选项"
                :data="dataList" :props="{key: 'id',label: 'name',}" :titles="['未展示','已展示']" @change="save" />
        </div>
    </custom-dialog>
</template>
<script setup>
import { MessageBox } from "@/js/utils/commonJs";
import { ElMessageBox } from "element-plus";
import {
  batchOpenDatabase,
  findOpenCloseDatabaseList,
} from "@/js/network/main";
import { reactive, toRefs } from "vue";
const emit = defineEmits(['update'])
// 双向绑定机制  初始化类型 默认值
const visible = defineModel({ default: false, type: Boolean });

const dataConfig = reactive({
  loading: false,
  dataList: [],
  idList: [],
  buttonLoading: false,
  editFlag:false
});

const { dataList, idList } = toRefs(dataConfig);

const save = () => {
    dataConfig.editFlag = true;
    dataConfig.loading = true;
    batchOpenDatabase({ idList: idList.value }).then(res => {
        dataConfig.loading = false;
        if (!res.success) {
            return;
        }
        MessageBox.success("保存成功！")
    }).catch(error => {
        dataConfig.loading = false;
    });
}
   const filterMethod = (query, item) => {
      return item.name.includes(query);
    }

const close = () => {
    if(dataConfig.editFlag){
        emit('update')
    }
}
    
const getDataList = () => {
  dataConfig.loading = true;
  findOpenCloseDatabaseList()
    .then((res) => {
        dataConfig.loading = false;
      if (!res.success) {
        MessageBox.error(res.msg);
        return;
      }
      if (res.data.openList?.length || res.data.closeList?.length) {
            let closeList = res.data.closeList || [];
            let openList = res.data.openList || [];
          dataList.value.push(...closeList, ...openList);
          idList.value.push(...openList.map((v) => v.id));
      }
    })
    .catch((error) => {
      dataConfig.loading = false;
      console.log(error, "catchError");
    });
};

getDataList();
</script>
<style lang="scss" scoped>
.transfer {
  margin-top: 20px;
  height: 600px;
}

:deep(.el-transfer-panel) {
  width: calc(50% - 85px) !important;
}

:deep(.el-transfer-panel__body) {
  height: 560px !important;
}

:deep(.el-button) {
  height: 36px !important;
}
</style>
